























































































































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../../../mixins/WidgetMixins';
import DialogMixins from '../../../../../mixins/DialogMixins';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { ICalculatedValues, ICustomers, IDefaultUser, IPaginate, IStore, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

const customersModule = namespace('customers');
const authModule = namespace('auth');
@Component({
  name: 'CustomersIndex',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    CreditUnionDefaultAdminDialog: loadView('pages/products/dialogs/CreditUnionDefaultAdminDialog'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class ProductIndex extends mixins(WidgetMixins, DialogMixins) {
  @customersModule.Getter('getAllCustomers') customers!: ICustomers[];
  @customersModule.Getter('getPagination') pagination!: IPaginate;
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  menu: boolean = false;
  options: any = {};
  searchTerm: string | null = null;
  date: string | null = null;
  headers: Array<{ text: string; value: string }> = [
    {
      text: '',
      value: 'avatar',
    },
    {
      text: 'Name',
      value: 'name',
    },

    {
      text: 'Email',
      value: 'emailAddress',
    },
    {
      text: 'Phone',
      value: 'contact',
    },
    {
      text: 'Status',
      value: 'isActive',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  searchTermChange(term: string): void {
    if (term) {
      const query = `?page=${this.currentPage}&size=${this.pagination.itemsPerPage}&search=${term}`;
      this.$store.dispatch('customers/listCreditUnions', query);
    } else {
      this.$store.dispatch(
        'customers/listCreditUnions',
        `?page=${this.currentPage}&size=${this.pagination.itemsPerPage}`,
      );
    }
  }

  handleOnSearchTermChange = debounce(this.searchTermChange, 800);
  @Watch('searchTerm')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTermChange(term);
  }

  @Watch('options', {
    deep: true,
  })
  onOptionsChange(paginate: IPaginate): void {
    const query = `?page=${paginate.page}&size=${paginate.itemsPerPage}${
      this.searchTerm ? '&search=' + this.searchTerm : ''
    }`;
    this.$store.dispatch('customers/listCreditUnions', query);
  }

  addUserAccount(payload: IDefaultUser): void {
    this.$store.dispatch('customers/addDefaultUser', {
      body: payload,
      id: this.$store.getters['customers/getCustomerDetails']?._id,
    });
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('customers/listCreditUnions', `?page=1&size=15`);
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
