var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"}},[_c('div',{staticClass:"d-flex flex-row  flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column mid-container mid-container-auto"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"loading":_vm.pageLoading,"small":"","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('customers/listCreditUnions', "?page=1&size=15")}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])]),_c('v-btn',{staticClass:"ft text-capitalize font-weight-medium text-sm",attrs:{"to":{ name: 'cu.customers.add' },"color":"accent"}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("add")]),_vm._v(" Create ")])]},proxy:true}])})],1),(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"},[_vm._m(0),_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-text-field',{staticClass:"ft font-weight-medium text-sm ma-2 black--text",staticStyle:{"max-width":"500px"},attrs:{"filled":"","hide-details":"","rounded":"","loading":_vm.isLoading,"dense":"","prepend-inner-icon":"search","placeholder":"Search through all customers ..."},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-data-table',{staticClass:"vx-shadow ft mt-2 data-list-list-view",staticStyle:{"margin-bottom":"80px !important"},attrs:{"headers":_vm.headers,"items":_vm.customers,"loading":_vm.pageLoading,"options":_vm.options,"server-items-length":_vm.pagination.total,"footer-props":{
              itemsPerPageOptions: [15, 30],
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{staticClass:"white--text",attrs:{"size":"35","color":"primary"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"align-items":"center"}},[_c('span',{staticClass:" ft font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.emailAddress",fn:function(ref){
            var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.emailAddress)),"target":"_blank"}},[_c('span',{staticClass:"ft cursor-pointer font-weight-medium text-sm"},[_vm._v(_vm._s(item.emailAddress))])])]}},{key:"item.isActive",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"vx-shadow",attrs:{"label":"","small":"","color":item.isActive ? '#8bf782c9' : 'rgba(247, 130, 130, 0.79)'}},[_c('span',{staticClass:"ft",style:({ color: item.isActive ? '#108407' : 'rgb(238, 7, 7)' })},[_vm._v(_vm._s(item.isActive ? 'ENABLED' : 'DISABLED'))])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","nudge-bottom":"40","nudge-left":"30","close-on-click":true,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"accent"}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("more_vert")])])]}}],null,true)},[_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',{staticClass:"ft font-weight-medium pt-2 pb-1",staticStyle:{"height":"auto !important"}},[_vm._v("ACTIONS ")]),(_vm.isLoading)?_c('v-progress-linear',{staticClass:"mt-1",attrs:{"light":"","indeterminate":"","height":"2px"}}):_vm._e(),_c('v-list-item',{staticStyle:{"min-height":"36px !important"},on:{"click":function($event){$event.stopPropagation();_vm.$router
                        .push({
                          name: 'cu.customers.add',
                          query: {
                            organizationId: item._id,
                          },
                        })
                        .catch(function () {})}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium text-sm"},[_c('div',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("edit")]),_c('span',{staticClass:"ml-2 pt-1"},[_vm._v("Edit Organization")])])])],1),_c('v-list-item',{staticStyle:{"min-height":"36px !important"},on:{"click":function($event){_vm.$router
                        .push({
                          name: 'cu.transactions',
                          query: {
                            page: 1,
                            size: 15,
                            organizationId: item._id,
                            orgName: encodeURIComponent(item.name),
                          },
                        })
                        .catch(function () {})}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium text-sm"},[_c('div',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("bar_chart")]),_c('span',{staticClass:"ml-2 pt-1"},[_vm._v("View Transactions")])])])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push().catch(function () {})}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium text-sm"},[_c('div',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("summarize")]),_c('span',{staticClass:"ml-2 pt-1"},[_vm._v("Settlement Report")])])])],1),_c('v-list-item',{staticStyle:{"min-height":"36px !important"},on:{"click":function($event){$event.stopPropagation();_vm.open('add');
                      _vm.$store.dispatch('customers/cacheCustomerDetails', item);}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium text-sm"},[_c('div',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("group_add")]),_c('span',{staticClass:"ml-2 pt-1"},[_vm._v("View Default Admin")])])])],1),_c('v-list-item',{staticStyle:{"min-height":"36px !important"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('customers/activateCreditUnionAccount', {
                        id: item._id,
                        body: { isActive: !item.isActive },
                      })}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium text-sm"},[_c('div',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(_vm._s(item.isActive ? 'person_off' : 'done_all'))]),_c('span',{staticClass:"ml-2 pt-1"},[_vm._v(_vm._s(item.isActive ? 'Disable' : 'Enable')+" Account")])])])],1)],1)],1)]}}],null,false,1501325508)})],1)]):_c('InternetConnection',{attrs:{"loading":_vm.pageLoading},on:{"refreshPage":function($event){return _vm.$store.dispatch('customers/list', '?page=1&size=15')}}}),_c('SnackBar',{attrs:{"snackbar":_vm.showSnackBar,"text":_vm.message,"timeout":_vm.timeout}}),_c('CreditUnionDefaultAdminDialog',{attrs:{"state":_vm.dialog('add'),"loading":_vm.isLoading,"dialogLoading":_vm.isDialogLoading},on:{"addUserAccount":_vm.addUserAccount,"actions":_vm.close}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 justify-space-between"},[_c('h3',{staticClass:"px-2 pt-3 pb-1 ft font-weight-medium text-sm"},[_vm._v(" All Registered Organizations ")])])}]

export { render, staticRenderFns }